/* 
 * @Author: 曹俊杰 
 * @Date: 2020-11-13 15:10:36
 * @Module: 发布 关注 粉丝 信息
 */
 <template>
  <div class="followInfo-box">
    <div class="followInfo-box-item hover"
         @click="$store.dispatch('homepage/toPersonal', {uuid:$route.query.uuid})">
      <div class="followInfo-box-item-t">{{data.published_count}}</div>
      <div class="followInfo-box-item-b">发布</div>
    </div>
    <div class="followInfo-box-item hover"
         @click="$store.dispatch('homepage/toFollowe', {uuid:$route.query.uuid,type:'followed'})">
      <div class="followInfo-box-item-t">{{data.following}}</div>
      <div class="followInfo-box-item-b">关注</div>
    </div>
    <div class="followInfo-box-item hover"
         @click="$store.dispatch('homepage/toFollowe', {uuid:$route.query.uuid,type:'follower'})">
      <div class="followInfo-box-item-t">{{data.follower}}</div>
      <div class="followInfo-box-item-b">粉丝</div>
    </div>
    <div class="followInfo-box-solid"
         style="left:112px"></div>
    <div class="followInfo-box-solid"
         style="right:112px"></div>
  </div>
</template>
 <script>
export default {
  props: {
    data: {
      type: Object,
      default: () => ({})
    }
  },
  components: {},
  data () {
    return {};
  },
  mounted () { },
  methods: {},
};
 </script>
 <style lang='scss' scoped>
.followInfo-box {
  width: 340px;
  height: 82px;
  background: #ffffff;
  box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  position: relative;
  &-item {
    text-align: center;
    &-t {
      line-height: 25px;
      font-size: 18px;
    }
    &-b {
      margin-top: 3px;
      font-size: 14px;
      line-height: 20px;
      color: #999;
    }
  }
  &-solid {
    width: 1px;
    height: 24px;
    background: #eeeeee;
    position: absolute;
  }
}
</style>